<template>
  <div class="prices mt-4 ml-8 mr-8">
    <v-container fluid>
      <v-row>
        <v-col>
          <h1 class="display-2 mb-5">Bestuhlungsanzeige</h1>
        </v-col>
      </v-row>
      <v-card color="default" outlined class="elevation-0">
        <v-card-text class="px-10 pt-5 pb-10">
          <div v-if="loading">
            <v-progress-linear
              color="primary"
              indeterminate
            ></v-progress-linear>
          </div>
          <v-simple-table v-else>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    ID
                  </th>
                  <th class="text-left">
                    Name
                  </th>
                  <th class="text-left">
                    Nummer
                  </th>
                  <th class="text-left">
                    Aktuelle Bestuhlung
                  </th>
                  <th class="text-left">
                    Neue Bestuhlung
                  </th>
                  <th class="text-left">
                    Nächste Buchung
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="room in rooms" :key="room.id">
                  <td>
                    <router-link :to="`/rooms/${room.id}`">
                      {{ room.id }}
                    </router-link>
                  </td>
                  <td>
                    <router-link :to="`/rooms/${room.id}`">
                      {{ room.name }}
                    </router-link>
                  </td>
                  <td>{{ room.number }}</td>
                  <td>
                    <v-btn
                      @click="openSeatingDialog(room)"
                      x-small
                      elevation="0"
                      fab
                      class="mr-4"
                    >
                      <v-icon>edit</v-icon>
                    </v-btn>
                    {{ room.seating.current ? room.seating.current.name : "-" }}
                  </td>
                  <td>
                    {{
                      room.seating.upcoming ? room.seating.upcoming.name : "-"
                    }}
                  </td>
                  <td>
                    <template v-if="room.booking">
                      {{ room.booking.title }}
                      (
                      <router-link :to="`/bookings/${room.booking.id}`">
                        #{{ room.booking.refid }}
                      </router-link>
                      )
                    </template>
                    <template v-else>-</template>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-container>

    <v-dialog v-model="seatingDialog" width="400">
      <v-card>
        <v-card-text class="pt-5" v-if="room">
          <v-select
            v-model="seating"
            outlined
            dense
            clearable
            :items="room.available_seatings"
            item-text="name"
            item-value="id"
            label="Bestuhlung"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="default" text @click="close()">
            Abbrechen
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="updateSeating()"
            :disabled="updating"
          >
            Bestätigen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "SeatingDisplay",

  data: () => ({
    rooms: [],
    room: null,
    seating: null,
    loading: true,
    seatingDialog: false,
    updating: false
  }),

  mounted() {
    this.fetchData();
  },

  methods: {
    fetchData() {
      this.loading = true;
      this.$api.http
        .get("/backoffice/seating-display")
        .then(response => {
          this.rooms = response.data;
        })
        .catch(() => {
          this.$toast.error("Fehler beim Laden der Daten.");
        })
        .finally(() => {
          this.loading = false;
        });
    },

    openSeatingDialog(room) {
      this.room = room;
      this.seating = room.seating.current ? room.seating.current.id : null;
      this.seatingDialog = true;
    },

    updateSeating() {
      this.updating = true;
      this.$api.http
        .put("/backoffice/rooms/" + this.room.id, {
          seating_id: this.seating ? this.seating : null
        })
        .then(() => {
          this.$toast.success("Bestuhlung erfolgreich aktualisiert.");
          this.seatingDialog = false;
          this.fetchData();
        })
        .catch(() => {
          this.$toast.error("Fehler beim Aktualisieren der Bestuhlung.");
        })
        .finally(() => {
          this.updating = false;
        });
    },

    close() {
      this.room = null;
      this.seating = null;
      this.seatingDialog = false;
    }
  }
};
</script>

<style scoped></style>
